/*******************************************************************************
 * NFL Confidence Pool FE - the frontend implementation of an NFL confidence pool.
 * Copyright (C) 2015-present Brian Duffey
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see {http://www.gnu.org/licenses/}.
 * Home: https://asitewithnoname.com/
 */
@import "../../styles/variables";

.nav-link {
  font-size: 25px;
  // stylelint-disable-next-line font-weight-notation
  font-weight: bold;
  line-height: 29px;
  color: inherit;
}

.nav-button {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}

.active {
  // stylelint-disable-next-line declaration-no-important
  color: $green-5 !important;
  background-color: $gray-2;
}

.nested {
  font-size: 18px;
  // stylelint-disable-next-line font-weight-notation
  font-weight: normal;
  line-height: 21px;
  color: inherit;
}
