/*******************************************************************************
 * NFL Confidence Pool FE - the frontend implementation of an NFL confidence pool.
 * Copyright (C) 2015-present Brian Duffey
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see {http://www.gnu.org/licenses/}.
 * Home: https://asitewithnoname.com/
 */
@import "../../styles/variables";

.sidebar {
  z-index: 1030;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 25px;
  /* stylelint-disable-next-line font-weight-notation */
  font-weight: bold;
  line-height: 29px;
  color: $gray-1;
  background-color: $black;
  box-shadow: 7px -10px 15px -5px $gray-6;
}

.close-menu {
  position: absolute;
  top: 5px;
  right: 10px;
}

.week-picker {
  color: inherit;
  background: none;
}

.mobile-menu {
  top: 5px;
  left: 10px;
  z-index: 1;
  color: $gray-1;
}

.mobile-top-bar {
  color: $gray-1;
  background-color: $black;
}

.btn-week-picker,
.btn-current-week {
  color: inherit;
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}

.current-week-link {
  height: 1.5rem;
  font-size: 1rem;
}
